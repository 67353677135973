import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <StyledSection id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Real estate riders should be useful</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Snapp to view</FeatureTitle>
          <FeatureText>
            Make your physical sign a digital platform
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Analytics</FeatureTitle>
          <FeatureText>
            Understand potential buyers and their habits
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Conversion</FeatureTitle>
          <FeatureText>
            Connect directly with leads, no more crossed wires
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Auto Import</FeatureTitle>
          <FeatureText>
            Import your listings from popular real estate platforms
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Re-Link</FeatureTitle>
          <FeatureText>Reuse single signs across multiple listings</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Customize</FeatureTitle>
          <FeatureText>
            Brand and customize your Snapplist signs to boost your brand
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </StyledSection>
)

export default Features


const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
`
const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
